import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  static targets = ["content"]
  static values = { 
    redirectTo: String,
    show: Boolean,
  }

  connect() {
    if (this.showValue) {
      setTimeout(() => {
        this.element.classList.add("active")
      }, 100)

      setTimeout(() => {
        this.element.classList.remove("active")
      }, 1100)
    }

    if (this.redirectToValue) {
      // setTimeout(() => {
      //   let frame = document.querySelector("turbo-frame#modal")
      //   frame.src = this.redirectToValue
      // }, 1100)
      // setTimeout(() => document.location.href = this.redirectToValue, 1100)    
      setTimeout(() => Turbo.visit(this.redirectToValue), 1000)    
    }

    document.addEventListener('notification:show', this.show);
  }

  disconnect() {
    document.removeEventListener('notification:show', this.show);
  }

  show = (event) => {
    this.contentTarget.innerHTML = event.detail.value
    lock(document.body)
    document.dispatchEvent(new Event('document:lock'));
    this.element.classList.add("active")
    if (event.detail?.modal) {
      this.element.classList.add(event.detail.modal)
    }
    
    setTimeout(() => {
      this.element.classList.remove("active")
      unlock(document.body)
      document.dispatchEvent(new Event('document:unlock'));
    }, 1000)
  }
}